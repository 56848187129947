<template>
    <div>
        <div v-for="mail in row.item.mails"
             :key="mail.id"
        >
            {{ mail.created_at | formatDate('DD.MM.YYYY HH:mm:ss') }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>